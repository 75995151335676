import '@brightspace-ui/core/components/breadcrumbs/breadcrumb.js';
import '@brightspace-ui/core/components/breadcrumbs/breadcrumb-current-page.js';
import '@brightspace-ui/core/components/breadcrumbs/breadcrumbs.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/tag-list/tag-list.js';
import { bodyCompactStyles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import '@app/shared/components/activities/activity-filter/activity-filter.js';
import '@app/shared/components/activities/activity-card/activity-card.js';
import '@app/shared/components/general/no-results/no-results.js';
import '@app/shared/components/general/nova-tag-list-item/nova-tag-list-item.js';
import ActivitiesHelper from '@shared/helpers/activities.js';
import Activity from '@shared/models/activity/activity.js';
import ActivityFilter from '@app/shared/models/activity-filter/activity-filter.js';
import { ActivitySchema } from '@shared/models/schema/activity/index.js';
import { CAREER_ITEM_TYPES } from '@shared/models/schema/job.js';
import CareerStream from '@app/shared/models/career-stream/career-stream.js';
import { LANDING_STREAM_TYPES } from '@shared/constants.js';
import LandingStream from '@app/shared/models/landing-stream/landing-stream.js';
import { LocalizeNova } from '@app/shared/mixins/localize-nova/localize-nova.js';
import { mapify } from '@shared/methods.js';
import { STATIC_TEST_IDS } from '@shared/helpers/playwright.js';

export default class ViewActivityCategory extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static properties() {
    return {
      params: { type: String },
      _activities: { state: true },
      _filter: { state: true },
      _viewingCareerStream: { state: true },
      _viewingLandingStream: { state: true },
      _careerFilterItems: { type: Array },
      _saveFilters: { type: Boolean, state: true },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      heading2Styles,
      heading3Styles,
      selectStyles,
      css`
        :host {
          display: block;
        }

        .activities-heading {
          margin-bottom: 1rem;
        }

        .filter {
          padding: 5px;
        }

        .activities {
          display: flex;
          flex-wrap: wrap;
          gap: 0.6rem clamp(0.6rem, 1.1vw, 2.5rem);
          justify-content: flex-start;
          transition: gap 0.25s ease-in-out;
        }

        .category-header {
          font-weight: 400;
          margin-bottom: 20px;
        }

        .category-subtitle {
          font-weight: 400;
          margin: 20px 0 28px 0;
        }

        .career-skills-intro-text {
          color: var(--d2l-color-ferrite);
          margin-bottom: 12px;
          margin-top: 36px;
        }

        .career-skills-list {
          margin-bottom: 36px;
        }

        /* Breakpoint specific to card size */
        @media (max-width: 1160px) {
          .activities {
            gap: 0.6rem 0.3rem;
          }
        }

        @media (max-width: 767px) {
          .activities {
            gap: 0.6rem clamp(3px, 1vw, 0.3rem);
            justify-content: center;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this._courses = [];
    this._programs = [];
    this._activities = [];
    this._skills = [];
    this._saveFilters = true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    this.skeleton = true;
    this._viewingCareerStream = !!this.params?.careerId;
    this._viewingLandingStream = !!this.params?.goalId;
    this._careerId = this.params?.careerId;
    const providers = await this.client.listTenants('provider');

    // get filters from the URL
    this._filter = new ActivityFilter({ ...this.session.user.getSetting('filters'), validProviders: providers });
    const search = window.location.search;
    if (search) {
      const searchParams = new URLSearchParams(search);
      const source = searchParams.get('source');
      if (source === 'url') {
        const filters = searchParams.get('filters');
        const decodedFilters = JSON.parse(atob(decodeURIComponent(filters)));
        this._filter = new ActivityFilter({ ...decodedFilters, validProviders: providers });
        this._saveFilters = false;
      } else if (source === 'none') {
        this._filter = new ActivityFilter({ validProviders: providers });
        this._saveFilters = false;
      }
    }

    await this.setupCareerFilter();
    const stream = await this._getStream();
    if (!stream) throw new Error('No matching stream found!');
    if (this._viewingCareerStream) {
      this._careerData = stream.careerData;
      this._filter.jobs = [];
      this._filter.lots = [];
      this._filter[this._careerFilterProp] = [this._careerId];
      this._careerData = this._careerFilterItemMap[this._careerId];
    }

    this._categoryDisplayName = stream.displayName ?? ActivitySchema.getTranslatedValue('category', stream.id);
    if (stream.subtitle) {
      this._categorySubtitle = stream.subtitle;
    } else {
      const potentialSubtitle = ActivitySchema.getTranslatedValue('category', stream.id);
      if (potentialSubtitle !== this._categoryDisplayName) {
        this._categorySubtitle = potentialSubtitle;
      }
    }

    await this._refreshActivities();
    const documentTitle = this.localize('view-activity-category.documentTitle.afterLoading', {
      categoryName: this._categoryDisplayName,
    });
    this.client.setDocumentTitle(documentTitle);
    this.skeleton = false;

    if (this._viewingCareerStream) {
      this._logCareerPageViewedEvent();
    } else if (this._viewingLandingStream) {
      this._logInterestedGoalPageViewedEvent();
    } else {
      this._logCategoryViewedEvent();
    }
  }

  async setupCareerFilter() {
    // Depending on tenant setting, reset certain career filters if they are not enabled anymore
    if (this.session.tenant?.hasTag('careerExplorer')) {
      // reset in case tenant setting has changed from LOTs to Job Titles or other career item type
      CAREER_ITEM_TYPES.forEach(item => {
        if (item !== this._careerFilterProp) {
          this._filter[item] = [];
        }
      });

      this._careerFilterItems = await this.client.getCareerItems(this._careerFilterProp);
      this._careerFilterItemMap = mapify(this._careerFilterItems);
    } else {
      this._filter.jobs = [];
      this._filter.lots = [];
      this._careerFilterItems = [];
      this._careerFilterItemMap = {};
    }
  }

  render() {
    return html`
      <d2l-breadcrumbs>
        <d2l-breadcrumb text="${this.localize('view-activity-category.programs')}" href="/activities"></d2l-breadcrumb>
        <d2l-breadcrumb-current-page text="${this._categoryDisplayName}"></d2l-breadcrumb-current-page>
      </d2l-breadcrumbs>
      <h2 class="d2l-heading-2 d2l-skeletize category-header">${this._categoryDisplayName}</h2>
      ${this._categorySubtitle ? html`<h3 class="d2l-heading-3 d2l-skeletize category-subtitle">${this._categorySubtitle}</h3>` : nothing}
      ${this._viewingCareerStream ? this._careerSkillsTemplate : nothing}

      <activity-filter
        .filter=${this._filter ?? new ActivityFilter()}
        .skills=${this._skills}
        .careerFilterItems=${this._viewingCareerStream || !this._careerFilterItems ? [] : this._careerFilterItems}
        .disabledFilters=${this._viewingCareerStream || this._viewingLandingStream ? ['lots', 'jobs'] : []}
        ?skeleton=${this.skeleton}
        ?saveFilters=${this._saveFilters}
        @filter-changed=${this._filterChanged}>
      </activity-filter>
      ${this._activities.length === 0 ? html`
      <no-results ?skeleton=${this.skeleton}></no-results>
      ` : html`
      ${this._activityList(this._programs, this.localize('view-activity-category.programs'))}
      ${this._activityList(this._courses, this.localize('view-activity-category.courses'))}
      `}
    `;
  }

  _activityList(activities, heading) {
    return activities.length > 0 ? html`
      <h3 class="d2l-heading-3 activities-heading d2l-skeletize">${heading}</h3>
      <div class="activities d2l-skeletize">
        ${repeat(activities, activity => activity.id, activity => html`
          <activity-card .activity=${activity}></activity-card>
        `)}
      </div>
    ` : nothing;
  }

  async _getStream() {
    if (this.params?.category === 'myList') {
      const { myList = [] } = this.session.settings;
      return { displayName: this.localize('activity.category.myList'), filters: { ...this._filter, id: myList }, id: 'myList' };
    } else if (this.params?.category === 'in-progress') {
      const inProgress = (await this.client.getInProgressActivities())?.map(activity => activity.id);
      return { displayName: this.localize('activity.category.inProgress'), filters: { ...this._filter, id: inProgress }, id: 'in-progress' };
    } else if (this._viewingCareerStream) {
      return this._getCareerStream(this.params.careerId);
    } else if (this._viewingLandingStream) {
      return this._getLandingStream();
    }
    return await this.client.getStreamFromPath(this.session.user.tenantId, this.params?.category);
  }

  async _refreshActivities() {
    const stream = await this._getStream();
    const isEmptyMyListStream = stream.id === 'myList' && stream.filters.id?.length === 0;

    const streamFilters = this.client.prepareFiltersForApiCall(stream.filters);
    const catalogFilters = this.client.prepareFiltersForApiCall(this._filter);
    const filtersConflict = stream.type === 'custom' && ActivitiesHelper.streamFiltersConflict(streamFilters, catalogFilters);

    this._activitiesInCategory = (isEmptyMyListStream || filtersConflict) ? [] : (await this.client.searchActivities({
      from: 0,
      size: 999,
      filters: {
        ...streamFilters,
        ...catalogFilters,
      },
      randomizeOrder: undefined,
      category: (stream.type === 'category' && stream.id !== 'myList' && stream.id !== 'in-progress') ? stream.id : stream.category,
      sort: stream.sort,
      property: stream.property,
      excludeCategories: stream.type === 'custom',
    })).hits.map(act => new Activity(act));

    await this._updateActivitySkillTags();

    this._skills = ActivitiesHelper.extractSkillsFreqSort(this._activitiesInCategory);

    if (this.params?.category === 'myList' || this.params?.category === 'in-progress') {
      delete this._filter.category;
    }
    this._activities = this._activitiesInCategory;
    this._programs = [];
    this._courses = [];
    this._activities.forEach(a => {
      a.type === 'program' ? this._programs.push(a) : this._courses.push(a);
    });

    this.requestUpdate();
  }

  async _updateActivitySkillTags() {
    if (this._filter.hasCareerFilter && this._filter[this._careerFilterProp]?.length > 0) {

      const careerFilterIds = this._filter[this._careerFilterProp];
      const filteredCareerItems = this._careerFilterItems.filter(c => careerFilterIds.includes(c.id));

      this._activitiesInCategory.forEach(activity => {
        activity.skills = activity.getCareerSkills(filteredCareerItems, this._careerSkillTypes);
      });
    }
  }

  async _filterChanged(e) {
    this._filter = new ActivityFilter(e.detail.filter);
    await this._refreshActivities();
    this.requestUpdate();
  }

  // 'both', 'specialized', or 'common'
  get _careerSkillTypes() {
    return this.session.tenant?.careerExplorer?.settings?.skillsIncluded;
  }

  get _careerFilterProp() {
    return this.session.tenant?.careerExplorer?.settings?.showLots
      ? 'lots'
      : 'jobs';
  }

  get _careerSkillsTemplate() {
    if (this._careerData === undefined) {
      return nothing; // nothing to show
    }

    const specializedSkillsArray = Object.values(this._careerData.skills.specialized);
    const commonSkillsArray = Object.values(this._careerData.skills.common);
    if (specializedSkillsArray.length === 0 && commonSkillsArray.length === 0) {
      return nothing;
    }

    const sortedSkills = Object.values([...commonSkillsArray, ...specializedSkillsArray]).sort((a, b) => {
      if (a.significance > b.significance) return -1;
      if (a.significance < b.significance) return 1;
      return 0;
    });

    const introText = this.session.tenant.learnerTerminology === 'member'
      ? this.localize('view-activity-category.career-skills.non-company-intro')
      : this.localize('view-activity-category.career-skills.company-intro', {
        companyName: this.session.tenant.name,
      });

    return html`
      <p class="career-skills-intro-text d2l-skeletize d2l-body-compact">${introText}</p>
      <d2l-tag-list class="d2l-skeletize career-skills-list" data-testid="${STATIC_TEST_IDS.careerSkillsList}">
        ${sortedSkills.map(s => html`<nova-tag-list-item text="${s.name}"></nova-tag-list-item>`)}
      </d2l-tag-list>
    `;
  }

  _getLandingStream() {
    const paramTokens = this.params.goalId.split('_');
    let property = undefined;
    let title = undefined;
    if (paramTokens.includes(LANDING_STREAM_TYPES.microlearning)) {
      property = {
        name: 'tags',
        value: 'fasttocomplete',
      };
    }

    if (this._careerFilterItemMap[paramTokens[1]]) {
      title = this._careerFilterItemMap[paramTokens[1]].name;
    }

    const { streamProperties } = new LandingStream({
      filters: this._filter,
      property,
      title,
    });

    return streamProperties;
  }

  _getCareerStream(careerId) {
    if (!this._careerFilterItemMap[careerId]) return;

    const { streamProperties } = new CareerStream({
      careerData: this._careerFilterItemMap[careerId],
      filters: this._filter,
      sourceType: this._careerFilterProp,
    });

    return streamProperties;
  }

  _logCategoryViewedEvent() {
    const category = this.params.category;
    this.client.logEvent({
      eventType: 'categoryViewed',
      category,
      totalProgramsInCategory: this._activitiesInCategory.length,
    });
  }

  _logCareerPageViewedEvent() {
    this.client.logEvent({
      eventType: 'careerPageViewed',
      careerId: this._careerData.id,
      careerName: this._careerData.name,
      // careerType doesn't need to be localized
      careerType: this._careerFilterProp === 'jobs' ? 'Job Title' : 'LOT Occupation',
      totalActivitiesInCareerStream: this._activitiesInCategory.length,
    });
  }

  _logInterestedGoalPageViewedEvent() {
    this.client.logEvent({
      eventType: 'interestedGoalPageViewed',
      goalId: this.params.goalId,
      totalActivitiesInInterestedGoalStream: this._activitiesInCategory.length,
    });
  }
}

window.customElements.define('view-activity-category', ViewActivityCategory);
