import '@brightspace-ui/core/components/switch/switch.js';

import { css, html, LitElement } from 'lit';
import { NovaPermissionMixin } from '../../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

export class EditActivitySwitch extends NovaPermissionMixin(LitElement) {
  static properties = {
    isDisabled: { type: Boolean },
    isActive: { type: Boolean },
    label: { type: String },
  };

  static get styles() {
    return css`
      /* Add styles here */
`;
  }

  constructor() {
    super();
    this.isDisabled = false;
    this.isActive = false;
    this.label = '';
    this.viewPermissions = ['activity:view'];
    this.updatePermissions = ['activity:status:update'];
  }

  handleChange(e) {
    const editActivitySwitch = new CustomEvent('switch-change', {
      detail: { isActive: e.target.on },
    });
    this.dispatchEvent(editActivitySwitch);
  }

  render() {
    return html`
      <d2l-switch
        ?disabled=${this.isDisabled}
        ?on="${this.isActive}"
        text="${this.label}"
        text-position="hidden"
        @change="${this.handleChange}">
      </d2l-switch>
    `;
  }
}

window.customElements.define('edit-activity-switch', EditActivitySwitch);
