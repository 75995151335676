import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';

import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/html-block/html-block.js';
import '@brightspace-ui/core/components/icons/icon-custom.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';
import '@brightspace-ui/core/components/menu/menu-item-checkbox.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { inputStyles } from '@brightspace-ui/core/components/inputs/input-styles.js';
import { labelStyles } from '@brightspace-ui/core/components/typography/styles.js';

import './nova-menu-dropdown.js';
import './nova-separator.js';
import * as menuItems from './helpers/menuItems.js';
import { dialogFullWidth, dialogHeaders, urlRegex } from './constants.js';
import { getActiveFormating, getEditor } from './helpers/editor.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import novaSvgIcons from './nova-svg-icons.js';

class NovaHtmlEditor extends LocalizeNova(LitElement) {
  static get properties() {
    return {
      _activeDialog: { type: String },
      _activeFormating: { type: Array },
      content: { type: String },
      disabled: { type: Boolean, reflect: true },
      _error: { type: Boolean },
      label: { type: String },
      required: { type: Boolean },
    };
  }

  static get styles() {
    return [
      inputStyles,
      inputLabelStyles,
      labelStyles,
      css`
        d2l-button-icon {
          border-radius: 3px;
          height: 2rem;
          width: 2rem;
        }

        .editor-body {
          height: 100%;
          overflow-y: scroll;
          padding: 0 14px;
        }

        .editor-wrapper {
          background-color: #ffffff;
          border: 1px solid var(--d2l-color-galena);
          border-radius: 6px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          height: 360px;
          margin-bottom: 18px;
          min-height: 288px;
          overflow: auto;
          padding: 0 0 0.3rem;
          resize: vertical;
        }

        .error {
          border: 2px solid var(--d2l-color-cinnabar);
        }

        .ProseMirror:focus {
          outline: none;
        }

        .selected {
          background: var(--d2l-color-celestine-plus-2);
        }

        .spaced-items {
          display: flex;
          justify-content: space-between;
          margin-bottom: 4px;
        }

        .toolbar {
          margin: 4px 2px 0 2px;
        }

        .toolbar-border {
          border-bottom: 1px solid var(--d2l-color-mica);
          margin: 0 12px;
        }
`,
    ];
  }

  constructor() {
    super();
    this._activeFormating = [];
    this._error = false;
  }

  firstUpdated() {
    this.container = this.shadowRoot.querySelector('#editor');
    this.editor = getEditor(this.container, this.content);

    this.editor.on('blur', ({ editor }) => {
      this._error = this.required && !editor.getText();
      this._dispatchEditorUpdate();
    });

    this.editor.on('focus', ({ editor }) => {
      this._updateActive(editor);
    });

    this.editor.on('selectionUpdate', ({ editor }) => {
      this._updateActive(editor);
    });
  }

  updated(changedProperties) {
    if (!changedProperties.get('content') && this.content && !this._hasSetContent) {
      this.editor.commands.setContent(this.content);
      this._hasSetContent = true;
    }
    if (changedProperties.has('disabled')) {
      this.editor.setOptions({ editable: !this.disabled });
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.editor.destroy();
  }

  _dispatchEditorUpdate() {
    const detail = { html: this.editor.getHTML(), json: this.editor.getJSON(), text: this.editor.getText() };
    this.dispatchEvent(new CustomEvent('nova-htmleditor-update', { detail }));
  }

  _hasFormat(format) {
    return this._activeFormating.includes(format);
  }

  _openDialog({ target }) {
    this._activeDialog = target.id;
    this.shadowRoot.querySelector('#dialog').opened = !!this._activeDialog;
  }

  _updateActive(editor) {
    this._activeFormating = getActiveFormating(editor);
  }

  _renderActiveDialogContent() {
    if (this._activeDialog === 'link-button') return this._editLinkDialogContent;
    if (this._activeDialog === 'edit-source') return this._editSourceDialogContent;
    if (this._activeDialog === 'preview') return this._previewDialogContent;
    if (this._activeDialog === 'word-count') return this._wordCountDialogContent;
  }

  _handleDialogClose(e) {
    if (this._activeDialog === 'link-button') {
      if (e.detail.action === 'done') {
        const url = this.shadowRoot.querySelector('#link-url')?.value;
        if (!urlRegex.test(url)) {
          e.preventDefault();
          return;
        } else {
          const href = url.startsWith('http') || url.includes('://') ? url : `https://${url}`;
          this.editor.chain().focus().extendMarkRange('link').setLink({ href }).run();
        }
      } else if (e.detail.action === 'unset') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
      }
    } else if (this._activeDialog === 'edit-source') {
      if (e.detail.action === 'done') {
        const sourceHTML = this.shadowRoot.querySelector('#source-string')?.value;
        if (!sourceHTML) {
          e.preventDefault();
          return;
        }
        this.editor.commands.setContent(sourceHTML);
      }
    }

    this._activeDialog = null;
  }

  get _editLinkDialogContent() {
    const previousUrl = this.editor.getAttributes('link').href;
    return html`
      <d2l-input-text id="link-url" label="URL" required value=${previousUrl}></d2l-input-text>
      <d2l-button slot="footer" primary data-dialog-action="done">Set link</d2l-button>
      <d2l-button slot="footer" data-dialog-action="unset">Unset link</d2l-button>
      <d2l-button slot="footer" data-dialog-action>Cancel</d2l-button>
    `;
  }

  get _editSourceDialogContent() {
    const sourceHTML = this.editor.getHTML();
    return html`
      <d2l-input-textarea id="source-string" label="Edit source code" required value=${sourceHTML}></d2l-input-textarea>
      <d2l-button slot="footer" primary data-dialog-action="done">Done</d2l-button>
      <d2l-button slot="footer" data-dialog-action>Cancel</d2l-button>
    `;
  }

  get _previewDialogContent() {
    return html`
      <d2l-html-block html=${this.editor.getHTML()}></d2l-html-block>
      <d2l-button slot="footer" primary data-dialog-action="done">Close</d2l-button>
    `;
  }

  get _wordCountDialogContent() {
    return html`
      <div class="spaced-items">
        <b>Count</b>
        <b>Total</b>
      </div>
      <div class="spaced-items">
        <span>Characters</span>
        <span>${this.editor.storage.characterCount.characters()}</span>
      </div>
      <div class="spaced-items">
        <span>Words</span>
        <span>${this.editor.storage.characterCount.words()}</span>
      </div>

      <d2l-button slot="footer" primary data-dialog-action="done">Close</d2l-button>
    `;
  }

  _getMenuTemplate({ description, disabled, hasText, icon: menuIcon, items = [], menuLabel, svg: menuSvg }) {
    const text = items.find(({ id }) => this._hasFormat(id))?.label || menuLabel;

    return html`
      <nova-menu-dropdown
        description=${description || text}
        ?disabled=${disabled}
        icon=${menuIcon}
        label=${menuLabel}
        svg=${menuSvg}
        text=${ifDefined(hasText ? text : undefined)}
      >
        ${repeat(items, ({ label }) => label, ({ action, icon, id, label, noToggle, svg }) => html`
          <d2l-menu-item-checkbox
            @d2l-menu-item-select=${action}
            ?disabled=${noToggle && this._hasFormat(id)}
            ?selected=${this._activeFormating?.includes(id)}
            text=${label}
            value=${id}
          >
            ${(icon || svg) ? html`
              <div slot="supporting">
                ${icon ? html`<d2l-icon icon=${icon}></d2l-icon>` : nothing}
                ${svg ? html`<d2l-icon-custom slot="icon">${novaSvgIcons[svg]}</d2l-icon-custom>` : nothing}
              </div>
            ` : nothing}
          </d2l-menu-item-checkbox>
        `)}
      </nova-menu-dropdown>
    `;
  }

  _renderLabel() {
    const labelClasses = {
      'd2l-input-label': true,
      'd2l-input-label-required': this.required && !this.disabled,
      'd2l-skeletize': true,
    };

    return this.label && !this.labelHidden && !this.labelledBy ? html`
      <span class="${classMap(labelClasses)}" aria-hidden="${ifDefined(this.disabled ? undefined : 'true')}">${this.label}</span>
    `	: nothing;
  }

  _toggleItalic() {
    this.editor.chain().focus().toggleItalic().run();
  }

  _toggleBold() {
    this.editor.chain().focus().toggleBold().run();
  }

  _handleUndo() {
    this.editor.chain().focus().undo().run();
  }

  _handleRedo() {
    this.editor.chain().focus().redo().run();
  }

  _handleFocus() {
    this.editor.commands.focus();
  }

  render() {
    return html`
      ${this._renderLabel()}

      <div class="editor-wrapper${this._error ? ' error' : ''}">
        <div class="toolbar">
          ${this._getMenuTemplate({ hasText: true, items: menuItems.getFormatItems(this.editor), menuLabel: 'Format' })}
          <nova-separator></nova-separator>

          <d2l-button-icon
            class=${this._hasFormat('bold') ? 'selected' : ''}
            icon="html-editor:bold"
            text="Bold"
            @click="${this._toggleBold}"></d2l-button-icon>

          <d2l-button-icon
            class=${this._hasFormat('italic') ? 'selected' : ''}
            icon="html-editor:italic"
            text="Italic"
            @click=${this._toggleItalic}></d2l-button-icon>

          ${this._getMenuTemplate({ icon: 'html-editor:underline', items: menuItems.getInlineFormatItems(this.editor), menuLabel: 'Inline Formats' })}
          <nova-separator></nova-separator>
          ${this._getMenuTemplate({ icon: 'html-editor:align-left', items: menuItems.getAlignmentItems(this.editor), menuLabel: 'Alignment' })}
          ${this._getMenuTemplate({ icon: 'html-editor:list-bullet', items: menuItems.getListItems(this.editor), menuLabel: 'List' })}
          <nova-separator></nova-separator>
          ${this._getMenuTemplate({ disabled: true, menuLabel: 'Table', svg: 'table' })}
          ${this._getMenuTemplate({ icon: 'tier1:plus-default', items: menuItems.getOtherItems(this.editor), menuLabel: 'Other Insert Options' })}
          <nova-separator></nova-separator>
          ${this._getMenuTemplate({ hasText: true, items: menuItems.getFontFamilyItems(this.editor, this.localize), menuLabel: 'Font' })}
          <nova-separator></nova-separator>
          ${this._getMenuTemplate({ hasText: true, items: menuItems.getFontSizeItems(this.editor, this.localize), menuLabel: 'Font Size' })}
          <nova-separator></nova-separator>

          <d2l-button-icon
            class=${this._hasFormat('link') ? 'selected' : ''}
            @click=${this._openDialog}
            icon="tier1:link"
            id="link-button"
            text="Link"></d2l-button-icon>
          <nova-separator></nova-separator>

          <d2l-button-icon @click=${this._openDialog} id="word-count" text="Word Count">
            <d2l-icon-custom id="word-count" slot="icon">
              ${novaSvgIcons['word-count']}
            </d2l-icon-custom>
          </d2l-button-icon>

          <d2l-button-icon @click=${this._openDialog} icon="tier1:preview" id="preview" text="Preview"></d2l-button-icon>
          <d2l-button-icon @click=${this._openDialog} icon="html-editor:source-editor" id="edit-source" text="Source Code"></d2l-button-icon>
          <nova-separator></nova-separator>

          <d2l-button-icon
            ?disabled=${!this.editor?.can().undo()}
            icon="tier1:undo"
            text="Undo"
            @click=${this._handleUndo}></d2l-button-icon>

          <d2l-button-icon
            ?disabled=${!this.editor?.can().redo()}
            icon="tier1:redo"
            text="Redo"
            @click=${this._handleRedo}></d2l-button-icon>

          <d2l-dialog
            id="dialog"
            title-text=${dialogHeaders[this._activeDialog]}
            @d2l-dialog-before-close=${this._handleDialogClose}
            width=${this._activeDialog === 'edit-source' ? dialogFullWidth : 600}>
            ${this._renderActiveDialogContent()}
          </d2l-dialog>
        </div>

        <div class="toolbar-border"></div>
        <div class="editor-body" @click=${this._handleFocus}>
          <div id="editor"></div>
        </div>
      </div>
    `;
  }
}

window.customElements.define('nova-htmleditor', NovaHtmlEditor);
